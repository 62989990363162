import * as React from "react";

import { cn } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";
import type { TeamData } from "@/data/team";
import { type CarouselApi } from "@/components/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import useCarouselTipEffect from "@/lib/useCarouselTipEffect";
import type { EmblaCarouselType, EmblaEventType } from "embla-carousel-react";
import useScreenSize from "@/lib/useScreenSize";

export interface CarouselCardsLawyersProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data: TeamData[] | TeamData[][];
  textColor: string;
  bg: string;
}

const CarouselCards = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselCardsLawyersProps
>(({ className, data, textColor, bg, ...props }, ref) => {
  const isArrayOfArrays = Array.isArray(data[0]);
  let idx = 0;

  const descriptionRefs = React.useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );

  const cardRefs = React.useRef<Array<React.MutableRefObject<HTMLDivElement>>>(
    [],
  );

  const [api, setApi] = React.useState<CarouselApi>();
  const [canScroll, setCanScroll] = React.useState(false);
  const [cardIndex, setCardIndex] = React.useState(0);
  const screenSize = useScreenSize();

  descriptionRefs.current = data.map(
    (_, i) => descriptionRefs.current[i] ?? React.createRef<HTMLDivElement>(),
  );

  cardRefs.current = data.map(
    (_, i) => cardRefs.current[i] ?? React.createRef<HTMLDivElement>(),
  );

  useCarouselTipEffect(api);

  React.useEffect(() => {
    let debounceTimeout: NodeJS.Timeout;

    const checkScroll = () => {
      if (!api) {
        return;
      }

      setCanScroll(api.canScrollNext() || api.canScrollPrev());
    };
    checkScroll();
    const debouncedCheckScroll = () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(checkScroll, 100);
    };

    window.addEventListener("resize", () => {
      debouncedCheckScroll();
    });

    return () => {
      clearTimeout(debounceTimeout);
      window.removeEventListener("resize", () => {
        debouncedCheckScroll();
      });
    };
  }, [api]);

  textColor = textColor || "text-primary";
  const color = textColor.replace("text-", "").replace("-foreground", "");
  let hoverColor =
    `hover:text-${color}-foreground` || "text-primary-foreground";
  let bgHoverColor = `hover:bg-${color}-foreground` || "bg-primary-foreground";
  if (textColor.endsWith("-foreground")) {
    hoverColor = `${hoverColor.replace("-foreground", "")}`;
  } else {
    bgHoverColor = `${bgHoverColor.replace("-foreground", "")}`;
  }

  const applyBgStyle = (emblaApi: EmblaCarouselType, cardIndex: number) => {
    //check width and user agent
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    const slideNodes = emblaApi.slideNodes();
    if (!slideNodes) {
      return;
    }
    if (!isMobile) {
      slideNodes.forEach((node, i) => {
        const target = node.querySelector(".card-content") as HTMLElement;
        const targeImage = node.querySelector(".card-image") as HTMLElement;
        const hasHoverEffect = target.classList.contains("card-hover-effect");
        if (hasHoverEffect) {
          target.classList.remove("card-hover-effect");
        }
        const lacksHoverBgEffect =
          !target.classList.contains("hover:bg-popover");
        if (lacksHoverBgEffect) {
          target.classList.add("hover:bg-popover");
          if (targeImage) {
            targeImage.classList.add(
              "group-hover:blur-0",
              "group-hover:filter-none",
            );
          }
        }
      });
      return;
    }
    slideNodes.forEach((node, i) => {
      const target = node.querySelector(".card-content") as HTMLElement;
      const targeImage = node.querySelector(".card-image") as HTMLElement;
      if (target) {
        if (i === cardIndex) {
          target.classList.add("card-hover-effect");
        } else {
          target.classList.remove("card-hover-effect", "hover:bg-popover");
          if (targeImage) {
            targeImage.classList.remove(
              "group-hover:blur-0",
              "group-hover:filter-none",
            );
          }
        }
      }
    });
  };

  const onSelect = React.useCallback(
    (emblaApi: EmblaCarouselType, eventName: EmblaEventType) => {
      setCardIndex(emblaApi.selectedScrollSnap());
    },
    [],
  );

  React.useEffect(() => {
    if (!api || isArrayOfArrays) {
      return;
    }
    applyBgStyle(api, cardIndex);

    api.on("select", onSelect);

    return () => {
      api?.off("select", onSelect);
    };
  }, [api, cardIndex, screenSize]);

  const cacheColors = cn(
    "hover:bg-primary-foreground",
    "hover:bg-primary",
    "hover:bg-secondary",
    "hover:bg-secondary-foreground",
    "hover:bg-popover",
    "hover:bg-popover-foreground",
    "hover:bg-accent",
    "hover:bg-accent-foreground",
    "hover:bg-muted",
    "hover:bg-muted-foreground",
    "hover:text-primary",
    "hover:text-secondary",
    "hover:text-popover",
    "hover:text-accent",
    "hover:text-muted",
    "hover:text-primary-foreground",
    "hover:text-secondary-foreground",
    "hover:text-popover-foreground",
    "hover:text-accent-foreground",
    "hover:text-muted-foreground",
  );
  const navigationButtonClassNames = cn(
    cacheColors,
    textColor,
    hoverColor,
    bgHoverColor,
  );

  const carouselItemClassNames = cn(
    "  min-w-[320px] max-w-[320px] basis-1/4 pl-2 ",
  );

  const cardClassNames = cn("relative bg-transparent p-0");

  const cardContentClassNames = cn(
    "card-content group flex aspect-square flex-col items-start justify-start  p-0 transition-all duration-500 ease-in-out hover:bg-popover ",
    `${bg} ${textColor}`,
  );

  const cardContentChildDivClassNames = cn(
    "flex  max-h-[12.5625rem] min-h-[12.5625rem] min-w-full flex-col justify-start gap-6 p-8",
  );

  const cardContentTitleClassNames = cn(" text-Header5 font-[500]");

  const cardContentDescriptionClassNames = cn(
    "min-h-[80px] min-w-0 text-[1.625rem] uppercase leading-[1.3rem]",
  );

  const cardImageClassNames = cn(
    "flex min-h-[340px] w-full flex-col-reverse items-start justify-start pb-6 pl-3 grayscale-[30%] filter transition-all duration-500 ease-in-out group-hover:blur-0 group-hover:filter-none",
  );

  const cardImageStyles = (item: TeamData): React.CSSProperties | undefined => {
    return {
      backgroundImage: `${
        item.processedImgSrc ? `url('${item.processedImgSrc}')` : "url()"
      }`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
    };
  };

  return (
    <Carousel
      setApi={setApi}
      className={cn(" w-full", className)}
      opts={{ skipSnaps: true }}
      {...props}
    >
      <CarouselContent className="-ml-2">
        {data.every((item) => !Array.isArray(item))
          ? data.map((item, index) => {
              item = item as TeamData;
              return (
                <CarouselItem key={index} className={carouselItemClassNames}>
                  <Card className={cardClassNames} key={index * 2}>
                    <CardContent
                      ref={cardRefs.current[index]}
                      className={cardContentClassNames}
                    >
                      <div className={cardContentChildDivClassNames}>
                        <div className={cardContentTitleClassNames}>
                          {item.name}
                        </div>
                        <div
                          id={`description-${index}`}
                          ref={descriptionRefs.current[index]}
                          className={cardContentDescriptionClassNames}
                        >
                          {item.function}
                        </div>
                      </div>
                      <div
                        className={cn("card-image", cardImageClassNames)}
                        style={cardImageStyles(item)}
                      ></div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              );
            })
          : data.map((items, index) => {
              items = items as TeamData[];
              return (
                <CarouselItem key={index} className={carouselItemClassNames}>
                  {items.map((item, indexItem) => {
                    item = item as TeamData;
                    idx = index === 0 && indexItem === 0 ? 0 : idx + 1;
                    return (
                      <Card
                        className={cn(
                          cardClassNames,
                          indexItem > 0 ? "pt-2" : "",
                        )}
                        key={idx}
                      >
                        <CardContent
                          ref={cardRefs.current[idx]}
                          className={cardContentClassNames}
                        >
                          <div className={cardContentChildDivClassNames}>
                            <div className={cardContentTitleClassNames}>
                              {item.name}
                            </div>
                            <div
                              id={`description-${idx}`}
                              ref={descriptionRefs.current[indexItem]}
                              className={cardContentDescriptionClassNames}
                            >
                              {item.function}
                            </div>
                          </div>
                          <div
                            className={cardImageClassNames}
                            style={cardImageStyles(item)}
                          ></div>
                        </CardContent>
                      </Card>
                    );
                  })}
                </CarouselItem>
              );
            })}
      </CarouselContent>
      {canScroll &&
        (data.every((item) => !Array.isArray(item)) ? (
          <div
            className={cn(
              "w-30 absolute right-[55px] top-[25px] flex flex-row  ",
            )}
          >
            <CarouselPrevious
              className={cn("-left-4 border-0", navigationButtonClassNames)}
              id="prev"
            />
            <CarouselNext
              className={cn("border-0", navigationButtonClassNames)}
              id="next"
            />
          </div>
        ) : (
          <div className={cn("")}>
            <CarouselPrevious
              className={cn(" border-0 ", navigationButtonClassNames)}
              id="prev"
            />
            <CarouselNext
              className={cn("border-0 ", navigationButtonClassNames)}
              id="next"
            />
          </div>
        ))}
    </Carousel>
  );
});

CarouselCards.displayName = "CarouselCardsLawyers";
export { CarouselCards as CarouselCardsLawyers };
